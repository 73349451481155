<template>
  <div
    class="item"
    :class="active == skill.id ? 'activeItem' : 'inactiveItem'"
    @click="handleSkillClick(skill.id)"
    v-tooltip="skill.name"
  >
    <div class="skillBox text-center">
      <div class="skilText">
        {{ skill.name }}
      </div>
    </div>
  </div>
</template>

<script>
import { truncate } from "../../../../../utils/utils";
export default {
  props: ["skill", "active"],
  components: {},
  data() {
    return {
      windowSize: 2000,
    };
  },

  mounted() {
    this.windowSize = window.innerWidth;
  },

  methods: {
    truncate,
    getImgUrl(url) {
      return url.split("/").pop();
    },
    handleSkillClick(id) {
      this.$emit("handleSkillClick", id);
    },
  },
};
</script>

<style lang="scss" scoped>
.Skills-carousel .item {
  text-align: center;
  padding: 20px;
  border: 1px solid #fff;
  white-space: nowrap;
  display: inline-block;
}

.Skills-carousel .item:hover,
.Skills-carousel .item.active,
.Skills-carousel .activeItem {
  border: 1px solid #ffc629;
  border-radius: 20px;
  cursor: pointer;
  box-shadow: 0 2px 4px 0 rgb(255 198 41 / 20%);
}
</style>
