<template>
  <div class="placeholder-card">
    <div class="nominated-list">
      <div class="blur"></div>
      <div class="nominated-list-card">
        <div class="nominated-list-card__header">
          <div class="nominated-list-card__img">
            <img
              class="avater"
              src="@/assets/images/talent-avatar.png"
              alt=""
            />
          </div>
          <div class="nominated-list-card__info">
            <div class="nominated-list-card__name">
              <span>Ahmed Shibob</span>
              <img src="@/assets/images/ic_verifed.svg" alt="" />
            </div>
            <div class="nominated-list-card__role">Front-end Developer</div>
          </div>
          <div class="nominated-list-card__experince">
            {{ 5 }}
            {{ 5 > 1 ? "years" : "year" }}
            Experince
          </div>
          <div class="nominated-list-card__availability">
            <ul>
              <li>
                <span class="nominated-list-card__availability__type"
                  >Full time</span
                >
                <span class="nominated-list-card__availability__at"
                  >Starts in 2 week(s)</span
                >
                <span class="nominated-list-card__availability__salary"
                  >1500 $</span
                >
              </li>
              <li>
                <span class="nominated-list-card__availability__type"
                  >Part time</span
                >
                <span class="nominated-list-card__availability__at"
                  >Starts immediately</span
                >
                <span class="nominated-list-card__availability__salary"
                  >500 $</span
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="nominated-list-card__description">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the dummy text of the printing and
          typesetting … Read more
        </div>

        <div class="nominated-list-card__assessments">
          <Assessments />
        </div>

        <div class="nominated-list-card__skills">
          <div class="skill-tags-wrapper">
            <div class="skill-tag">
              <span class="skill-name"> skill name</span>
              <span class="skill-experience">
                5 Y
                <span style="padding: 0"> & </span>
                5 M of experience
              </span>
            </div>
            <div class="skill-tag">
              <span class="skill-name"> skill name</span>
              <span class="skill-experience">
                5 Y
                <span style="padding: 0"> & </span>
                5 M of experience
              </span>
            </div>

            <div class="skill-tag">
              <span class="skill-name"> skill name</span>
              <span class="skill-experience">
                5 Y
                <span style="padding: 0"> & </span>
                5 M of experience
              </span>
            </div>

            <div class="skill-tag">
              <span class="skill-name"> skill name</span>
              <span class="skill-experience">
                5 Y
                <span style="padding: 0"> & </span>
                5 M of experience
              </span>
            </div>
          </div>
        </div>

        <div class="nominated-list-card__actions">
          <div class="nominated-list-card__questions">
            <div class="nominated-list-card__questions-text">
              <p>Add your questions if you have any ?</p>
              <input type="text" v-if="false" />
            </div>
            <div class="nominated-list-card__questions-send">
              <img src="@/assets/images/send.svg" alt="" />
            </div>
          </div>
          <div class="nominated-list-card__take-action">
            <button class="btn btn-interested">Request an interview</button>

            <button class="btn btn-not-interested">
              <img src="@/assets/images/heart.svg" alt="" />
              Not interested
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-img">
        <img src="@/assets/images/ic_profile-locked.svg" alt="" />
      </div>
      <div class="card-context">
        <h5>
          {{ $t("nomination.thereAreMoreWaysToFindTalents") }}
        </h5>
        <p>
          {{ $t("nomination.viewMatchedTalents") }}
        </p>
      </div>
      <div class="card-action">
        <button
          class="btn"
          @click="$router.push({ path: `/sq/client/matching` })"
        >
          {{ $t("nomination.findTalents") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Assessments from "@/pages/Client/nominatedProfiles/components/assessments/assessments.vue";

export default {
  components: {
    Assessments,
  },
};
</script>

<style lang="scss" scope>
.nominated-list {
  .blur {
    background: rgba(
      255,
      255,
      255,
      0.2
    ); // Make sure this color has an opacity of less than 1
    backdrop-filter: blur(8px);
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 10;
    border-radius: 26.4px;
  }

  .nominated-list-card__expired {
    display: flex;
    font-size: 19.98px;
    color: #ff6f59;
    line-height: 19.98px;
    font-weight: 600;
    background-color: rgb(255 212 206);
    border-radius: 27.5px;
    height: 55px;
    width: 146px;
    align-items: center;
    justify-content: center;
    right: 5rem;
    position: absolute;
    top: 3rem;
  }

  .nominated-list-card {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border-radius: 26.4px;
    box-shadow: 11px 8px 56px 0 #422e8717;
    padding: 35px 58px;
    margin-bottom: 2rem;
    position: relative;
    @media (max-width: 576px) {
      padding: 20px;
      margin-bottom: 1rem;
    }

    &__header {
      display: flex;
      align-items: center;
      @media (max-width: 576px) {
        flex-direction: column;
        align-items: flex-start;
      }
    }
    &__img .avater {
      height: 90px;
      width: 90px;
      border-radius: 50%;
      @media (max-width: 576px) {
        height: 68px;
        width: 68px;
      }
    }
    &__info {
      margin-left: 1rem;
    }

    &__name {
      text-align: left;
      margin-bottom: 0.5rem;
      margin-top: 0.6rem;
      @media (max-width: 576px) {
        margin-bottom: 0;
        margin-top: 0;
      }

      span {
        font-size: 21.6px;
        color: #292929;
        line-height: 31px;
        font-weight: 700;
        @media (max-width: 576px) {
          font-size: 16px;
          color: #292929;
          text-align: center;
          line-height: 40.9px;
        }
      }

      img {
        height: 26px;
        width: 25px;
        margin-left: 0.5rem;
      }
    }
    &__role {
      font-size: 19.8px;
      color: #414042;
      line-height: 32.23px;
      font-weight: 600;

      @media (max-width: 576px) {
        font-size: 14.15px;
        color: #414042;
        text-align: center;
        line-height: 22.72px;
        text-align: left;
      }
    }

    &__availability {
      display: flex;
      align-items: flex-end;
      ul {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 0;
        padding-left: 0;

        li {
          display: flex;
          flex-wrap: wrap;
          background-color: #f8f8f8;
          border-radius: 23px;
          color: #292929;
          padding: 10px 26px;
          margin-right: 10px;
          align-items: center;
          @media (max-width: 576px) {
            padding: 5px 10px;
            margin-top: 5px;
            margin-bottom: 5px;
          }

          .nominated-list-card__availability__type {
            margin-right: 5px;
            font-weight: 500;
            font-size: 17.5px;
            color: #292929;
            @media (max-width: 576px) {
              font-size: 12px;
            }
          }

          .nominated-list-card__availability__at {
            font-size: 14px;
            color: #808285;
            margin-right: 5px;
            @media (max-width: 576px) {
              font-size: 10px;
            }
          }

          .nominated-list-card__availability__salary {
            font-size: 22px;
            color: #373a3b;
            font-weight: 700;
            @media (max-width: 576px) {
              font-size: 15px;
            }
          }
        }
      }
    }

    &__description {
      color: #9f9d9d;
      font-size: 16px;
      font-weight: 500;
      text-align: left;
      word-break: break-all;
      margin-top: 25px;
      @media (max-width: 576px) {
        font-size: 11px;
        margin-top: 10px;
      }

      span {
        font-size: 16px;
        color: #000000;
        text-decoration: underline;
        cursor: pointer;
        @media (max-width: 576px) {
          font-size: 11px;
        }
      }
    }
  }

  .nominated-list-card__experince {
    font-size: 19px;
    color: #422e87;
    text-align: center;
    line-height: 25.78px;
    font-weight: 500;
    display: flex;
    align-items: center;
    margin: 0 1rem;
    @media (max-width: 576px) {
      font-size: 15px;
    }

    &:before {
      display: flex;
      border-radius: 50%;
      width: 8px;
      height: 8px;
      background-color: #d7d3e6;
      content: "";
      margin-right: 5px;
      @media (max-width: 576px) {
        display: none;
      }
    }

    &:after {
      display: flex;
      border-radius: 50%;
      width: 8px;
      height: 8px;
      background-color: #d7d3e6;
      content: "";
      margin-left: 5px;
      @media (max-width: 576px) {
        display: none;
      }
    }
  }

  .nominated-list-card__skills {
    margin: 1rem 0;
    padding: 1rem 0;
    padding-bottom: 8px;
    border-top: 1px solid #edebeb;
    border-bottom: 1px solid #edebeb;

    .skill-tags-wrapper {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      align-items: baseline;
      .skill-tag {
        display: flex;
        flex-wrap: wrap;
        border: 1px solid #eee;
        border-radius: 18px;
        color: #646464;
        padding: 6px 14px;
        margin-right: 10px;
        margin-bottom: 10px;
        background-color: #fafafa;
        align-items: center;
        background-color: #7d67c91a;
        border-radius: 20px;
        .skill-name {
          font-size: 15px;
          margin-right: 5px;
          color: #422e87;
          font-weight: 500;
          @media (max-width: 576px) {
            font-size: 14px;
          }
        }

        .skill-experience {
          font-size: 12px;
          padding: 4px 15px;
          border-radius: 5px;
          color: #494d55;
          font-weight: 500;
          background-color: #7d67c91a;
          border-radius: 15px;
          color: #373a3b;
          @media (max-width: 576px) {
            font-size: 11px;
          }
        }
      }
    }
  }

  .nominated-list-card__actions {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #edebeb;
    padding-bottom: 14px;
    @media (max-width: 576px) {
      flex-direction: column-reverse;
    }

    .nominated-list-card__salaries {
      display: flex;
      flex-direction: row;

      .nominated-list-card__salay {
        display: flex;
        align-items: center;
        margin-right: 2rem;

        .nominated-list-card__salay-icon {
          margin-right: 0.5rem;

          img {
            height: 23px;
            width: 22px;
          }
        }
        .nominated-list-card__availability {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          .nominated-list-card__type {
            font-size: 18px;
            color: #292929;
            font-weight: 600;
            margin-bottom: 0.3rem;
          }

          .nominated-list-card__when-to-start {
            font-size: 14px;
            color: #808285;
          }
        }

        .nominated-list-card__amount {
          font-size: 22px;
          color: #373a3b;
          font-weight: 700;
          margin-left: 3rem;
        }
      }
    }

    .nominated-list-card__take-action {
      margin-left: auto;

      .btn-not-interested {
        border: 1px solid #422e8726;
        border-radius: 12.49px;
        padding: 16px 32px;
        font-size: 19.98px;
        font-weight: 600;
        background: #fff;
        color: #422e87;
        margin-left: 1rem;
        justify-content: center;
        align-items: center;
        display: flex;
        @media (max-width: 576px) {
          padding: 10px 30px;
          width: 100%;
          margin-bottom: 1rem;
          margin-left: 0;
        }
        font-size: 15px;

        img {
          height: 21px;
          width: 21px;
             margin-inline-end: 0.5rem;
        }
      }

      .btn-interested {
        border: 1px solid #422e8726;
        border-radius: 12.49px;
        padding: 16px 32px;
        font-size: 19.98px;
        font-weight: 600;
        color: #fff;
        background: #422e87;
        @media (max-width: 576px) {
          padding: 10px 30px;
          width: 100%;
          margin-bottom: 1rem;
          font-size: 15px;
        }
      }
    }

    .nominated-list-card__questions {
      display: flex;
      width: 50%;
      justify-content: space-between;
      align-items: center;
      background-color: #f3f3f3;
      border-radius: 35.5px;
      @media (max-width: 576px) {
        width: 100%;
        padding: 15px 20px;
      }

      &-text {
        display: flex;
        align-content: center;
        flex-wrap: wrap;

        p {
          margin-bottom: 0;
          @media (max-width: 576px) {
            font-size: 11px;
          }
        }
      }

      &-send {
        img {
          height: 22px;
          width: 22px;
          @media (max-width: 576px) {
            height: 18px;
            width: 18px;
          }
          cursor: pointer;
          &:hover {
            transform: scale(1.1);
          }
        }
      }
    }
  }
}

.blur {
  background: rgba(
    255,
    255,
    255,
    0.2
  ); // Make sure this color has an opacity of less than 1
  backdrop-filter: blur(8px); // This be the blur
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 10;
  border-radius: 26.4px;
}

.placeholder-card {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 26.4px;
  box-shadow: 11px 8px 56px 0 rgb(66 46 135 / 9%);
  margin-bottom: 2rem;
  justify-content: center;
  position: relative;

  .card {
    display: flex;
    flex-direction: row;
    background-color: #ffffff;
    border-radius: 26.4px;
    padding: 35px 58px;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 85%;
    transform: translate(-50%, -50%);
    z-index: 12;
    @media (max-width: 576px) {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }

    .card-img {
      width: 150px;
      img {
        height: 111px;
        width: 121px;
      }
    }

    .card-context {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: left;
      @media (max-width: 576px) {
        text-align: center;
      }
      h6 {
        font-size: 25px;
        color: #292929;
        line-height: 40px;
        font-weight: 900;
        @media (max-width: 576px) {
          font-size: 20px;
          font-weight: 900;
        }
      }

      p {
        font-size: 17px;
        color: #9f9d9d;
        line-height: 33px;
        font-weight: 500;
        margin: 0;
        @media (max-width: 576px) {
          font-size: 12px;
          line-height: 20px;
        }
      }
    }

    .card-action {
      margin-left: auto;
      display: flex;
      align-items: center;
      @media (max-width: 576px) {
        margin-left: auto;
        margin-right: auto;
        margin-top: 1rem;
      }

      .btn {
        font-weight: 800;
        color: #292929;
        background-color: #ffc629;
        border-color: #ffc629;
      }
    }
  }
}
</style>