<template>
  <div class="nominated-list">
    <nominatedItem
      v-for="talent in talents"
      :key="talent.id"
      :talent="talent"
      :currentSkillId="currentSkillId"
      @notInterested="notInterested"
      @requestAnInterview="requestAnInterview"
      class="position-relative"
      @inquirySent="$emit('inquirySent')"
    />

    <RequestAnInterviewModal
      v-if="isOpen"
      :isOpen="isOpen"
      :isNomination="true"
      :currentTalent="currentTalent"
      @update="isOpen = $event"
      @intervewRequested="intervewRequested"
    />

    <NotInterestedModal
      v-if="isOpenNotInterested"
      :isOpenNotInterested="isOpenNotInterested"
      :currentTalent="currentTalent"
      @update="isOpenNotInterested = $event"
      @talentNotInterested="talentNotInterested"
    />
  </div>
</template>

<script>
import RequestAnInterviewModal from "@/pages/Client/nominatedProfiles/components/requestAnInterviewModal/requestAnInterviewModal.vue";
import NotInterestedModal from "@/pages/Client/nominatedProfiles/components/notInterestedModal/notInterestedModal.vue";
import NominatedItem from "@/pages/Client/nominatedProfiles/components/nominatedItem/nominatedItem.vue";

export default {
  data() {
    return {
      showMore: false,
      isOpen: false,
      currentTalent: null,
      isOpenNotInterested: false,
      baseUrl: process.env.VUE_APP_AWS_BASE_URL,
      inquiryList: [],
    };
  },
  components: {
    RequestAnInterviewModal,
    NotInterestedModal,
    NominatedItem,
  },
  props: {
    talents: {
      type: Array,
    },
    currentSkillId: {
      type: Number
    }
  },
  methods: {
    requestAnInterview(talent) {
      this.currentTalent = talent;
      this.isOpen = true;
    },
    intervewRequested() {
      this.currentTalent = null;
      this.$emit("updatedNominationList");
    },
    notInterested(talent) {
      this.currentTalent = talent;
      this.isOpenNotInterested = true;
    },
    talentNotInterested() {
      this.currentTalent = null;
      this.$emit("inquirySent");
    },
  },
};
</script>
