<template>
  <transition name="modal-fade">
    <div
      class="modal align-items-center NotInterestedBox-modal"
      role="dialog"
      aria-labelledby="modalTitle"
      aria-describedby="modalDescription"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="border-0 modal-header justify-content-center">
            <button type="button" class="btn-close" @click="close"></button>
          </div>

          <div class="modal-body no-border">
            <div class="row">
              <div class="mb-4 text-center col-12 NotInterestedBox">
                <h2 class="bold">
                  {{$t('nomination.notInterestedIn')}}
                  {{ currentTalent.talent.basicInfo.full_name }}
                </h2>
                <p>
                 
                  {{$t('nomination.letUsUnderstandMoreWhyYouReNotInterestedInThisTalent')}}
                </p>
                <hr />
                <div class="p-0 mb-3">
                  <label for="">
                    {{$t('nomination.PleaseEnterTheReason')}}
                  </label>
                  <textarea
                    dir="auto"
                    class="p-3 form-control"
                    :placeholder="$t('nomination.PleaseEnterTheReason')"
                    rows="2"
                    v-model="$v.reason.$model"
                  ></textarea>
                  <div class="mt-2 d-flex">
                    <p
                      class="text-end w-100"
                      :class="{
                        'text-error': !$v.reason.maxLength,
                      }"
                    >
                       {{$t('nomination.maxNumberOfCharacter5000')}}
                    </p>
                    <!--        <VulidateError
                      class="d-flex"
                      :validationField="$v.reason"
                      :errorMsg="
                        !$v.reason.maxLength
                          ? 'Reason should be 5000 character maximum'
                          : ''
                      "
                      :params="[{ maxLength: { number: '5000' } }]"
                    /> -->
                  </div>
                </div>
                <hr />
              </div>
            </div>
            <div class="row">
              <div class="applyViewBtnWrapper d-flex">
                <div class="">
                  <button @click="close" type="button" class="btn cancelbtn">
                    {{$t('nomination.cancel')}}
                  </button>
                </div>

                
                <div class="baseBtn">
                  <button
                    @click="notInterested"
                    :disabled="!$v.reason.maxLength || isSending"
                    type="button"
                    class="btn btnstyleUpdate btnSecondary"
                  >
                     {{$t('nomination.confirm')}}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from "vuex";
// import VulidateError from "@/components/shared/VulidateError/vulidateError.vue";
import { maxLength } from "vuelidate/lib/validators";

export default {
  name: "notInterestedModal",
  props: ["isOpenNotInterested", "currentTalent"],
  components: {
    /* VulidateError */
  },
  validations: {
    reason: {
      maxLength: maxLength(5000),
    },
  },
  data() {
    return {
      reason: "",
      isSending: false,
    };
  },
  watch: {
    isOpenNotInterested: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue) {
          document.body.classList.add("modal-open");
        } else {
          document.body.classList.remove("modal-open");
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/getUser",
    }),
  },
  methods: {
    async notInterested() {
      this.$v.reason.$touch();
      if (this.$v.$anyError && this.isSending) {
        return;
      }

      this.isSending = true;
      await this.axios
        .put(`/api/client/nomination/${this.currentTalent.id}`, {
          reason: this.reason ? this.reason : "",
          status: "not_interested",
        })
        .then(() => {
          this.$emit("talentNotInterested");
          this.close();
        }).finally(() => { 
          this.isSending = false;
        });
    },
    close() {
      document.body.classList.remove("modal-open");
      this.$emit("update", false);
    },
  },
};
</script>


<style>
.modal-open {
  overflow: hidden;
}
</style>


<style lang="scss" scoped>

.modal-body.no-border {
  overflow-y: auto !important;
}
.NotInterestedBox {
  h2 {
    font-size: 30px;
    color: #422e87;
    font-weight: 700;
  }

  .text-error {
    color: rgb(255, 111, 89);
  }
  .text-right {
    text-align: right;
  }

  textarea {
    resize: none;
  }

  p {
    font-size: 16px;
    font-style: normal;
    color: #a6a6a6;
  }

  hr {
    border-top: 1px solid #7f7f7f;
  }

  label {
    font-size: 15px;
    color: #4b4b4b;
    letter-spacing: -0.15px;
    font-weight: 600;
    text-align: left;
    display: block;
    margin-bottom: 5px;
  }
}

.applyViewBtnWrapper {
  justify-content: right;
  .cancelbtn {
    font-size: 18px;
    color: #000000;
    font-weight: 600;
    background-color: #fff;
    border-radius: 12px;
    height: 50px;
    display: flex;
    align-items: center;
    border: 2px solid #c5c5c5;
    margin: 5px;

    &:hover {
      border: 2px solid #c5c5c5 !important;
    }

    &:focus-visible {
      border: 2px solid #c5c5c5 !important;
    }
  }

  .btnstyleUpdate {
    font-size: 18px;
    color: #000000;
    font-weight: 600;
    background-color: #ffc629;
    border-radius: 12px;
    height: 50px;
    display: flex;
    align-items: center;
    margin: 5px;
  }
}
</style>

