<template>
  <div class="matching-skills mb-5">
    <div class="container">
      <div class="row align-items-center" :class="{'tw-opacity-60 tw-cursor-not-allowed': isLoading}">
        <div class="col-md-12" v-if="skills.length > 0 && isResponse">
          <carousel
            :items="6"
            :loop="false"
            :margin="10"
            :nav="true"
            :autoWidth="true"
            :dots="false"
            :responsive="{
              0: {
                items: 3,
              },
              576: {
                items: 2,
              },
              768: {
                items: 3,
              },
              1024: {
                items: 5,
              },
            }"
            class="matching-skills-carousel Skills-carousel"
          >
            <skill-card
              v-for="(skill, i) in skills"
              :key="i"
              :skill="skill"
              :class="{'tw-opacity-60 !tw-cursor-not-allowed': isLoading}"
              @handleSkillClick="handleSkillClick($event)"
              :active="activeSillParent"
            />
          </carousel>
        </div>

        <div
          v-if="!isResponse"
          class="matching-skills-carousel Skills-carousel"
        >
          <div class="d-flex align-items-center justify-content-center">
            <skeleton-loader-vue
              type="rect"
              :width="800"
              :height="30"
              animation="fade"
              rounded
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import carousel from "vue-owl-carousel";
import skillCard from "../skillCard/skillCard.vue";
import "./carousel.scss";
export default {
  components: { carousel, skillCard },
  props: ["skills", "isResponse", "isLoading"],
  data() {
    return { activeSillParent: 0 };
  },
  watch: {
    skills(newSkills) {
      this.activeSillParent = newSkills[0]?.id;
    },
  },
  methods: {
    handleSkillClick(id) {
      if (this.isLoading) return;
      this.activeSillParent = id;
      this.$emit("handleSkillClick", id);
    },
  },
};
</script>

<style lang="scss" >
.owl-carousel .owl-item {
  display: flex;
  margin-bottom: 2px;
  @media (max-width: 576px) {
    margin: 10px;
  }
}
</style>

<style lang="scss" scoped>
.matching-skills {
  @media (max-width: 576px) {
    margin-bottom: 1rem !important;
  }
}
</style>