var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"matching-skills mb-5"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row align-items-center",class:{'tw-opacity-60 tw-cursor-not-allowed': _vm.isLoading}},[(_vm.skills.length > 0 && _vm.isResponse)?_c('div',{staticClass:"col-md-12"},[_c('carousel',{staticClass:"matching-skills-carousel Skills-carousel",attrs:{"items":6,"loop":false,"margin":10,"nav":true,"autoWidth":true,"dots":false,"responsive":{
            0: {
              items: 3,
            },
            576: {
              items: 2,
            },
            768: {
              items: 3,
            },
            1024: {
              items: 5,
            },
          }}},_vm._l((_vm.skills),function(skill,i){return _c('skill-card',{key:i,class:{'tw-opacity-60 !tw-cursor-not-allowed': _vm.isLoading},attrs:{"skill":skill,"active":_vm.activeSillParent},on:{"handleSkillClick":function($event){return _vm.handleSkillClick($event)}}})}),1)],1):_vm._e(),(!_vm.isResponse)?_c('div',{staticClass:"matching-skills-carousel Skills-carousel"},[_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('skeleton-loader-vue',{attrs:{"type":"rect","width":800,"height":30,"animation":"fade","rounded":""}})],1)]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }