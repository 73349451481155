<template>
  <div class="support">
    <div v-if="isLoading">
      <div class="support__haeder">
        <div class="support__haeder-text">
          <div class="mb-2 d-flex align-items-center justify-content-start">
            <skeleton-loader-vue
              type="rect"
              :width="100"
              :height="30"
              animation="wave"
              rounded
            />
          </div>
        </div>
      </div>
      <div class="support__body">
        <div class="mb-4 support__body-user-massege">
          <div class="support__body-user-massege-box">
            <div class="mb-2 d-flex align-items-center justify-content-start">
              <skeleton-loader-vue
                type="rect"
                :width="500"
                :height="30"
                animation="wave"
                rounded
              />
            </div>
          </div>
        </div>
        <div class="support__body-user-massege">
          <div class="support__body-user-massege-box">
            <div class="mb-2 d-flex align-items-center justify-content-start">
              <skeleton-loader-vue
                type="rect"
                :width="500"
                :height="30"
                animation="wave"
                rounded
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="inquiryList.length && !isLoading">
      <div class="support__haeder">
        <div class="support__haeder-text">
          {{ $t('nomination.support')}}
        </div>
        <div class="support__haeder-view" @click="openSupport()">
           {{ $t('nomination.veiwAll')}}
        </div>
      </div>
      <div class="support__body">
        <div v-for="inquiry in inquiryList" :key="inquiry.created_at">
          <div class="support__body-user-massege" v-if="!inquiry.system">
            <div class="support__body-user-massege-box">
              <div class="support__body-user-massege-name" v-if="inquiry.author">{{inquiry.author}}</div>
              <p>
                {{ inquiry.body }}
              </p>
            </div>
          </div>

          <div class="support__body-user-massege" v-if="inquiry.system">
            <div class="support__body-user-massege-img">
              <img src="@/assets/images/ic_squadio-logo-lg-2x.png" alt="" />
            </div>
            <div
              class="support__body-user-massege-box support__body-user-massege-system"
            >
              <div class="support__body-user-massege-name">Squadio Team</div>
              <p class="support__body-user-massege-text">
                {{ inquiry.body }}
              </p>
            </div>
          </div>
          <span class="support__body-user-massege-date">{{
            getData(new Date(inquiry.created_at * 1000))
          }}</span>
        </div>
      </div>
    </div>

    <InquiryModal
      v-if="isOpenInquiry"
      :isOpenInquiry="isOpenInquiry"
      @update="update()"
      :currentTalent="currentTalent"
    />
  </div>
</template>

<script>
import moment from "moment";
import InquiryModal from "@/pages/Client/nominatedProfiles/components/inquiryModal/inquiryModal.vue";

export default {
  props: {
    talent: {
      type: Object,
    },
    inquiryList: {
      type: Array,
    },
    isLoading: {
      type: Boolean,
    },
  },
  components: {
    InquiryModal,
  },
  mounted() {},
  data() {
    return {
      isOpenInquiry: false,
      currentTalent: null,
    };
  },
  methods: {
    openSupport() {
      this.currentTalent = this.talent.id;
      this.isOpenInquiry = true;
    },
    getData(date) {
      if (date)
        return `${moment(date).format("dddd, MMMM Do, YYYY [at] h:mm A")}`;
    },
    update($event) {
      this.isOpenInquiry = $event;
      this.$emit("inquirySent");
    },
  },
};
</script>

<style lang="scss">
.support {
  display: flex;
  margin: 1rem 0;
  flex-direction: column;

  .support__haeder {
    display: flex;
    justify-content: space-between;
    &-text {
      font-size: 19px;
      color: #1e2022;
      font-weight: bold;
      @media (max-width: 576px) {
        font-size: 15px;
      }
    }
    &-view {
      font-size: 19px;
      color: #422e87;
      font-weight: bold;
      cursor: pointer;
      @media (max-width: 576px) {
        font-size: 15px;
      }
    }
  }

  &__body {
    margin-top: 1rem;

    .support__body-user-massege-system {
      background-color: rgb(245 245 245);
    }

    &-user-massege {
      display: flex;
      flex-direction: row;

      &-img {
        margin-right: 1rem;

        img {
          height: 62px;
          width: 62px;
        }
      }
      &-box {
        width: 100%;
        background-color: #ece7fc;
        padding: 1rem 1.5rem;
        border-radius: 14.74px 14.74px 14.74px 0;
        font-size: 14.74px;
        font-style: normal;
        color: #1e2022;
        text-align: left;
        p {
          font-size: 14.74px;
          font-style: normal;
          color: #1e2022;
          text-align: left;
          margin: 0;
          @media (max-width: 576px) {
            font-size: 12px;
          }
        }
      }
    }
  }

  .support__body-user-massege-date {
    font-size: 12.64px;
    font-style: normal;
    color: #a5a5a5;
    text-align: right;
    display: block;
    margin: 0.5rem 0;
  }
}
</style>