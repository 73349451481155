
<template>
  <div>
    <div class="mb-3 row">
      <div class="col-12 talents-text">
        <h1 v-if="!isLoaded" class="semibold">
          {{ $t('nomination.weAreReserving') }}
           {{ nominationsCount > 1 ?  $t('nomination.these') : $t('nomination.this')}}
          {{ nominationsCount > 2 ? nominationsCount : "" }}

          {{ nominationsCounttext() }}
           {{ $t('nomination.forYou') }}
        </h1>
        <div
          class="mb-3 d-flex align-items-center justify-content-center"
          v-if="isLoaded"
        >
          <skeleton-loader-vue
            type="rect"
            :width="500"
            :height="30"
            animation="wave"
            rounded
          />
        </div>
        <p v-if="!isLoaded">
           {{ $t('nomination.goodDay') }} <span>{{ userName }}!</span>  {{ $t('nomination.wereLookingForwardToHelpYouHireTheBestTalentsBasedOnYourNeeds') }}
        </p>
        <div
          class="mb-3 d-flex align-items-center justify-content-center"
          v-if="isLoaded"
        >
          <skeleton-loader-vue
            type="rect"
            :width="400"
            :height="30"
            animation="wave"
            rounded
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["isLoaded", "nominationsCount"],
  components: {},
  data() {
    return {
      userName: "",
    };
  },
  mounted() {
    this.userName = JSON.parse(localStorage.getItem("user")).first_name;
  },
  methods: {
    nominationsCounttext() {
      if (this.nominationsCount > 1) {
        if (this.nominationsCount == 2) {
          return this.$t('nomination.twoTalents')
        }
        if (this.nominationsCount >= 3 && this.nominationsCount <= 10) {
          return this.$t('nomination.talents')
        }
        else {
          return this.$t('nomination.talent')
        }

      }
      else {
        return this.$t('nomination.talent')
      }

    }
  },
};
</script>

<style lang="scss" scoped>
.talents-text h1 {
  font-weight: 600;
  font-size: 3em;
  margin-bottom: 1.5rem;
}

@media (max-width: 768px) {
  .mb-5 {
    margin-bottom: 1rem !important;
  }

  .talents-text h1 {
    font-size: 2em;
  }

  .talents-text p {
    font-size: 12px;
    margin-bottom: 0;
  }
}

@media (max-width: 576px) {
  .talents-text h1 {
    font-weight: 600;
    font-size: 25px;
    color: #292929;
    text-align: center;
    line-height: 37.8px;
  }

  .talents-text p {
    font-size: 12px;
    line-height: 16.2px;
    font-weight: 500;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.meetingTime {
  padding: 30px;
  font-size: 22px;
  font-weight: 500;
  color: rgba(159, 157, 157, 1);
  background-color: rgba(255, 255, 255, 1);
  border-radius: 22px;
  box-shadow: 0 15px 30px 0 rgb(0 0 0 / 3%);
  margin-bottom: 20px;
  letter-spacing: 0.05rem;

  @media (max-width: 575.98px) {
    font-size: 18px;
    font-weight: 500;
  }

  span {
    display: block;
    color: #292929;

    @media (max-width: 575.98px) {
      font-size: 16px;
      margin-top: 1rem;
    }
  }
}
</style>
  