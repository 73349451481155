import axios from "axios";

export default {
  data() {
    return {
      cancelTokenSource: null,
    };
  },
  methods: {
    createCancelToken() {
      this.cancelTokenSource = axios.CancelToken.source();
      return this.cancelTokenSource.token;
    },
    cancelRequest(message = "Request canceled due to route change") {
      if (this.cancelTokenSource) {
        this.cancelTokenSource.cancel(message);
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    this.cancelRequest();
    next();
  },
  beforeDestroy() {
    this.cancelRequest();
  },
};
