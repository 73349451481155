var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"placeholder-card"},[_c('div',{staticClass:"nominated-list"},[_c('div',{staticClass:"blur"}),_c('div',{staticClass:"nominated-list-card"},[_c('div',{staticClass:"nominated-list-card__header"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"nominated-list-card__experince"},[_vm._v(" "+_vm._s(5)+" "+_vm._s(5 > 1 ? "years" : "year")+" Experince ")]),_vm._m(2)]),_c('div',{staticClass:"nominated-list-card__description"},[_vm._v(" Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the dummy text of the printing and typesetting … Read more ")]),_c('div',{staticClass:"nominated-list-card__assessments"},[_c('Assessments')],1),_vm._m(3),_c('div',{staticClass:"nominated-list-card__actions"},[_c('div',{staticClass:"nominated-list-card__questions"},[_c('div',{staticClass:"nominated-list-card__questions-text"},[_c('p',[_vm._v("Add your questions if you have any ?")]),(false)?_c('input',{attrs:{"type":"text"}}):_vm._e()]),_vm._m(4)]),_vm._m(5)])])]),_c('div',{staticClass:"card"},[_vm._m(6),_c('div',{staticClass:"card-context"},[_c('h5',[_vm._v(" "+_vm._s(_vm.$t("nomination.thereAreMoreWaysToFindTalents"))+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.$t("nomination.viewMatchedTalents"))+" ")])]),_c('div',{staticClass:"card-action"},[_c('button',{staticClass:"btn",on:{"click":function($event){return _vm.$router.push({ path: `/sq/client/matching` })}}},[_vm._v(" "+_vm._s(_vm.$t("nomination.findTalents"))+" ")])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nominated-list-card__img"},[_c('img',{staticClass:"avater",attrs:{"src":require("@/assets/images/talent-avatar.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nominated-list-card__info"},[_c('div',{staticClass:"nominated-list-card__name"},[_c('span',[_vm._v("Ahmed Shibob")]),_c('img',{attrs:{"src":require("@/assets/images/ic_verifed.svg"),"alt":""}})]),_c('div',{staticClass:"nominated-list-card__role"},[_vm._v("Front-end Developer")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nominated-list-card__availability"},[_c('ul',[_c('li',[_c('span',{staticClass:"nominated-list-card__availability__type"},[_vm._v("Full time")]),_c('span',{staticClass:"nominated-list-card__availability__at"},[_vm._v("Starts in 2 week(s)")]),_c('span',{staticClass:"nominated-list-card__availability__salary"},[_vm._v("1500 $")])]),_c('li',[_c('span',{staticClass:"nominated-list-card__availability__type"},[_vm._v("Part time")]),_c('span',{staticClass:"nominated-list-card__availability__at"},[_vm._v("Starts immediately")]),_c('span',{staticClass:"nominated-list-card__availability__salary"},[_vm._v("500 $")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nominated-list-card__skills"},[_c('div',{staticClass:"skill-tags-wrapper"},[_c('div',{staticClass:"skill-tag"},[_c('span',{staticClass:"skill-name"},[_vm._v(" skill name")]),_c('span',{staticClass:"skill-experience"},[_vm._v(" 5 Y "),_c('span',{staticStyle:{"padding":"0"}},[_vm._v(" & ")]),_vm._v(" 5 M of experience ")])]),_c('div',{staticClass:"skill-tag"},[_c('span',{staticClass:"skill-name"},[_vm._v(" skill name")]),_c('span',{staticClass:"skill-experience"},[_vm._v(" 5 Y "),_c('span',{staticStyle:{"padding":"0"}},[_vm._v(" & ")]),_vm._v(" 5 M of experience ")])]),_c('div',{staticClass:"skill-tag"},[_c('span',{staticClass:"skill-name"},[_vm._v(" skill name")]),_c('span',{staticClass:"skill-experience"},[_vm._v(" 5 Y "),_c('span',{staticStyle:{"padding":"0"}},[_vm._v(" & ")]),_vm._v(" 5 M of experience ")])]),_c('div',{staticClass:"skill-tag"},[_c('span',{staticClass:"skill-name"},[_vm._v(" skill name")]),_c('span',{staticClass:"skill-experience"},[_vm._v(" 5 Y "),_c('span',{staticStyle:{"padding":"0"}},[_vm._v(" & ")]),_vm._v(" 5 M of experience ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nominated-list-card__questions-send"},[_c('img',{attrs:{"src":require("@/assets/images/send.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nominated-list-card__take-action"},[_c('button',{staticClass:"btn btn-interested"},[_vm._v("Request an interview")]),_c('button',{staticClass:"btn btn-not-interested"},[_c('img',{attrs:{"src":require("@/assets/images/heart.svg"),"alt":""}}),_vm._v(" Not interested ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-img"},[_c('img',{attrs:{"src":require("@/assets/images/ic_profile-locked.svg"),"alt":""}})])
}]

export { render, staticRenderFns }